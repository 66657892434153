import {
	redirect,
	replace,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { getUserId } from '#app/utils/auth.server'

export const meta: MetaFunction = () => [
	{ title: 'AI-Driven Recruiting Services - Moonhub' },
]

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)

	// if we have a user id, redirect to the dashboard
	if (userId) {
		return replace('/org')
	}

	return redirect('/login')
}
